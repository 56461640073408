import React from "react";
import { Link } from "gatsby";

import {
  SectionWrapper,
  FlexCol,
  SEO,
  LargeButton,
  SmallButton,
  H1,
  Text
} from "src/components";

const FourOhFourPage = () => (
  <SectionWrapper>
    <SEO title="404: Page Not Found" />
    <FlexCol
      justifyContent="center"
      aligItems="center"
      minHeight="90vh"
      maxWidth={420}
      mx="auto"
    >
      <H1 children={`404`} textAlign="center" />
      <Text
        children={`We are currently in the midst of redesigning our website. You may have followed an old or broken link.`}
        my={3}
      />
      <LargeButton children={`Go home`} as={Link} to="/" variant="primary" />
      <Text children={"Need something now?"} textAlign="center" mt={7} />
      <SmallButton children={"Contact us"} as={Link} to="/contact" />
    </FlexCol>
  </SectionWrapper>
);

export default FourOhFourPage;
